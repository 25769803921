import React from "react"
import Socials from "./socials"
import mon from "../images/logos/mon.png"
import sawday from "../images/logos/sawdays-big.png"


const Footer = () => (
    <div>
        <div style={{display: `flex`, justifyContent: `flex-end`}}>   
            <a href="https://www.discoveranglesey.com/" rel="noreferrer noopener" target="_blank">
                <img src={mon} alt="Discover Anglesey"/>
            </a>  
            <a href="https://www.sawdays.co.uk/britain/wales/anglesey/house-in-the-yard/" rel="noreferrer noopener" target="_blank">
                <img src={sawday} alt="Trip Advisor Travellers Choice Award"/>
            </a>
        </div>
        <footer style={{marginTop: `20px`, paddingTop: "20px", borderTop: `1px solid #b2d7dc` }}>
            <h4><a href="tel:+44 (0) 7811 404268">+44 (0) 7811 404268</a></h4>
            <h4><a href="tel:+44 (0) 1248 440860">+44 (0) 1248 440860</a></h4>
            <h4><a href="mailto: Noel and Delyth Sumner <thehouseintheyard@gmail.com>">thehouseintheyard@gmail.com</a></h4>
            <h4>Llangaffo, Isle of Anglesey LL60 6LY</h4>
            © {new Date().getFullYear()}, House-in-the-Yard 
            <Socials />
            <a href="https://peterbroadhead.co.uk" target="_blank" className="dev_tag" rel="noreferrer">Built by Peter</a>
        </footer>
    </div>
)

export default Footer
