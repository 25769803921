import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import MenuStatic from "./menuStatic"
import Socials from "./socials"
import hityLogoMain from "../images/logos/hity-logo-cirlcle-trans-70.png"

const Header = ({ siteTitle }) => (
  <header style={{marginBottom: `1.45rem`}}>
    <div className="nav_wrapper">
      <div className="title_main">
        <Link to="/" className="logo">
          <img src={hityLogoMain} alt="House-in-the-Yard Logo" style={{height: `70px`}}/>      
          <span>{siteTitle}</span>
        </Link>
        <Link to="/availability" className="book_now">Check Availability</Link>
      </div>
      <MenuStatic />
      <Socials />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
